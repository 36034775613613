import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Brushes can be used to draw a barchart. This gives an easy way to input or adjust values with the mouse !!!
The d3 brush is a complex piece of logic taking care of events and user feed-backs. Among other characterics it exposes a nice selection rectangle which can be used as a bar !!! `}</p>
    <p>{`2D-brushes come in two flavors see `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-brush"
      }}>{`official API v4 doc`}</a>{` `}</p>
    <ul>
      <li parentName="ul">{`d3.brushX()`}</li>
      <li parentName="ul">{`d3.brushY()`}</li>
    </ul>
    <p>{`Let's try both of them on this example.`}</p>
    <h2>{`Code comments`}</h2>
    <p>{`Source code can be examined and modified thanks to the great `}<a parentName="p" {...{
        "href": "http://blockbuilder.org/AlainRo/9264cd08e341f2c92f020c39642c34d1",
        "title": "target='_blank'"
      }}>{`Blockbuilder`}</a></p>
    <p>{`Code is made of:`}</p>
    <ul>
      <li parentName="ul">{`CSS `}</li>
      <li parentName="ul">{`a data definition in the form of an array of objects:  index:2, value: 23.34, ...`}</li>
      <li parentName="ul">{`two almost identical barchart generators (horizontal/vertical) allowing to drag a bar to a new value`}</li>
      <li parentName="ul">{`a refresh function which propagates data updates into the two barcharts`}</li>
    </ul>
    <p>{`Enjoy !!`}</p>
    <iframe src="https://cdn.rawgit.com/AlainRo/9264cd08e341f2c92f020c39642c34d1/raw/5b4cd7390e0595c917eb7c383d745721fc635dec/index.html" className="resp-iframe" frameBorder="0" height="700" width="690" marginHeight="0" marginWidth="0" scrolling="no" style={{
      "backgroundColor": "#ffffff"
    }}>
    </iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      